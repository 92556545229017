@media only screen and (max-width: 600px) {
  
  .education-details-container {
    width: 130% !important;
    .accordion-styles {
      width: 100% !important;
    }
    .form-container {
      .education-form {
        padding: 0.5rem;
        width: 90% !important;
      }
      width: auto !important;
    }
    .accordion-label {
        font-size: 0.75rem !important;
    }
  
  }
  .education-save-btn {
    background-color: red;
    width: 130%  !important;
    margin-left: 0 !important;
    margin-top: 1rem;
  }
  .css-2royc9 {
    margin-top: 0rem !important;
  //  width: 130% !important;
}

}

.education-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .accordion-styles {
    width: 70%;
  }
  .accordion-label {
    font-size: 0.875rem;
}
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  .education-form {
    padding: 1rem;
    width: 70%;
  }

}
.education-save-btn {
    width: 70%;
    margin-top: 1rem;
    margin-left: 15%;
}