.about-us-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .about-us-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .about-us-container h2 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .about-us-container p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .about-us-container ul {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .about-us-container li {
    margin-bottom: 5px;
  }
  
  .about-us-container a {
    color: #007bff; /* Bootstrap's default link color */
  }
  
  .about-us-container a:hover {
    text-decoration: underline;
  }
  