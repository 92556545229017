.user-profile-account {
    margin: 1rem 0 0 1rem;
     font-size: 0.75rem;
      font-weight: 700;
       letter-spacing: normal;
       text-transform: uppercase;
}
.avatar-bg {
  border: 0.10rem solid;
}
.user-profile-name {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0 !important;
    margin-top: 0.1rem !important;
    font-family: Segoe UI !important;
}
.user-profile-email {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem !important;
    margin-top: 0.1rem !important;
    font-family: Segoe UI !important;
}
.myprofile-item {
    a {
      display: flex;
      color: inherit;
      text-decoration: none;
    }
  }
  
