
a{
  text-decoration: none;
  color: white;
}

.footer-title{
  color: white;
  text-transform: uppercase;
}

.footer-container a:hover{
  text-decoration: underline;
}

.footer-item a{
  margin-top: 1rem;
}

li{
  list-style: none;
}

.footer-container{
  background-color: #2B366E;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
}


// .footer-container-light{
//   background-color: #2B366E;
//   padding: 1rem;
//   display: flex;
//   align-items: start;
// }

// .footer-container-dark{
//   background-color: #121212;
//   padding: 1rem;
//   display: flex;
//   align-items: start;
// }

.footer-text{
  color: white;
}

.logo-container{
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem; 
}

@media only screen and (max-width:600px) {
  .logo-container{
    // width: 100%;
  }
  .footer-container .catagories-container{
    // width: 100%;
  }
  // .footer-container-light .catagories-container{
  //   width: 100%;
  // }
  // .footer-container-dark .catagories-container{
  //   width: 100%;
  // }
  .text-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-container{
    flex-direction: column;
  }
  // .footer-container-light{
  //   flex-direction: column;
  // }
  // .footer-container-dark{
  //   flex-direction: column;
  // }
}

.logo-wrapper{
  display: flex;
  align-items: center;
}

.catagories-container{
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.footer-item{
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.container{
  padding:1rem;
}
