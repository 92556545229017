@media only screen and (max-width: 600px) {
  .profile-body {
    .css-2royc9 {
      margin-top: -2rem;
      width: 130% !important;
    }
    .css-mjpk2e-MuiButtonBase-root-MuiStepButton-root {
      margin: -25px -27px !important;
      padding: 24px 0px !important;
    }
    .MuiStepLabel-root {
      &.MuiStepLabel-horizontal {
        margin-left: 35px !important;
      }
    }
    div {
      width: 130% important;
      // background-color: red;
      .css-11qjisw {
        width: 120%;
      }
    }

  }

}
.user-profile-container {
  display: flex;
  .profile-tabs {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: "Roboto", sans-serif;
    text-transform: none;
  }
}
.profile-body {
  //  padding: 1rem;
  width: 100%;

  // .MuiBox-root {
  //     background-color: aqua;
  //     width: -webkit-fill-available;
  // }
}
