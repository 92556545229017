.dialog-titles-container {
    .oa-dialog-title {
        display: flex;
        justify-content: center;
        font-size: 1rem;
        font-weight: 600;
    }

    .oa-dialog-sub-title {
        display: flex;
        justify-content: center;
        font-size: 0.875rem;
        font-weight: 500;
    }

}

.dialog-action-footer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 1rem !important;
    position: relative;
    margin-left: 1.5rem !important;
}

@media only screen and (max-width: 600px) {
    .oa-dialog-title {
        font-size: 0.75rem !important;
        padding: 0.2rem;
        display: flex;
        justify-content: center;
        font-weight: 600 !important;
    }

    .oa-dialog-sub-title {
        margin-left: -0.5rem;
        font-size: 0.70rem !important;
    }
}