.state-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem;

    .state-item {
        padding: 0.5rem;
        &:hover {
            cursor: pointer;
            transform: scale(1.03); 
            transition: 0.3s; 
        }

        .state-image {
            border-radius: 0.3rem;

           
        }
    }
}