.form-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .identity-form {
        padding: 1rem;
        width: 50%;
    }
}
@media only screen and (max-width: 600px) {
    .MuiStepper-root {
        p {
            display: flex !important;
        }
        .css-vnkopk-MuiStepLabel-iconContainer {
            padding-left: 10px !important;
            padding-right: 1px !important;
        }
    }
    .form-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 120%;
        .identity-form {
            padding: 1rem;
            width: 100%
        }
    }
}