// Define some base variables for colors and spacing
$primary-color: #3498db;
$secondary-color: #2ecc71;
$background-color: #f4f4f4;
$text-color: #333;
$card-background: #ffffff;
$card-shadow: rgba(0, 0, 0, 0.1);

.place-order-container {
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: $background-color;
}

.checkout-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    max-width: 1200px;
    width: 100%;
}

section {
    background-color: $card-background;
    border-radius: 8px;
    box-shadow: 0 2px 4px $card-shadow;
    padding: 1.5rem;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

section:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

h1, h2, h3, h4 {
    color: $text-color;
    font-family: 'Roboto', sans-serif;
}


button {
    background-color: $primary-color;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    text-align: center;
}

button:hover {
    transform: translateY(-2px);
}

button:active {
    transform: translateY(1px);
}

.login-details-section {
    // Styles specific to login details
}

.order-summary-section {
    // Styles specific to order summary
}

.price-details-section {
    // Styles specific to price details
}

.payment-options-section {
    // Styles specific to payment options
}
