.chips-list {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}
.add-doc-btn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.file-name-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category-doc-chip-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem;
}

.category-doc-chip {
    margin: 1rem
}

@media only screen and (max-width: 600px) {
    .chips-list {
        display: flex;
     flex-direction: column;
    }
    .add-doc-btn {
        display: flex;
        justify-content: center;
        button {
            width: -webkit-fill-available;
        }
    }
    .category-doc-chip {
        margin: 0.2rem !important;
    }
}