.drawer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.list-items {
  justify-content: center !important;
margin-top: 0.5rem !important;

}
.list-items-text {
    text-decoration: none;
}
.list-items-icon {
    display: flex;
    align-items: center;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
background-color: transparent !important;
}