

.application-details-container {
    .application-details-header {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0.5rem 2rem 0.5rem;
        .header-back-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon-back {
                display: flex;
                margin-right: 1rem;
                cursor: pointer;
            }
        }
        .header-text {
            font-size: 1.25rem;
            font-weight: 600;
            color: #000000;

        }
    }
    padding: 0 3rem 3rem 3rem;

    .details-section {
        margin-bottom: 0rem !important;
        border-radius: 0.3rem;

        .MuiListItem-root {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        .css-10hburv-MuiTypography-root {
            font-size: .80rem !important;
        }
    }
    .css-1lenk52-MuiList-root {
        padding: 0!important;
    }
    .application-details-data {
        width: 50%;
        background-color: #f3f3f3;
        padding: 1.3rem 0rem 1.3rem 0rem;
        margin: 0.5rem 0 0.5rem 0;

        .application-detail-label {
            font-weight: 500;
            font-size: 1rem;
            margin-left: 1.5rem;
        }

        .application-detail-value {
            margin-top: 0.3rem;
            margin-left: 1.5rem;
         .document-list {
            padding: 0 !important;
         }

        }
    }
    .application-details-data-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0.75rem 0 0.75rem 0;
    }
}

@media only screen and (max-width:600px) {
    .application-details-container {
    padding: 0;    
    }
     .application-detail-value {
       word-break: break-all;
    }

}

