.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .left-login-container {
    width: 350px;
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: 600px) {
    .left-login-container {
      width: 0px;
    }
    .right-login-container {
      padding: 0;
    }
  }
  .right-login-container {
    width: 300px;
    padding: 1rem 1.5rem 1.5rem 3.5rem;
    .login-modal-cross {
      display: flex;
      justify-content: flex-end;
      .cross-icon {
        cursor: pointer;
      }
    }
    .cancel-forgot-password {
      display: flex;
      justify-content: center;
    }
    .cancel-title {
      font-size: 1rem;
      cursor: pointer;
      margin-top: 1rem;
    }
    .reset-password-heading {
      line-height: normal;
      color: #000;
    }
    .login-signup-button {
      text-transform: none;
      font-size: 1rem;
      letter-spacing: 0.07rem;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .forgot-password {
      display: flex;
      justify-content: flex-end;
      h6 {
        cursor: pointer;
      }
    }
    .no-account-text {
      cursor: pointer;
      font-weight: 600;
    }
    .no-account-heading {
      display: inline-flex;
      align-items: baseline;
      justify-content: center;
      width: inherit;
      margin: 0.5rem 0;
    }
    .or-login-option {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #44475b;
      line-height: 0.1em;

      span {
        color: #44475b;
        background: #fff;
        padding: 0 0.5rem;
      }
    }

    .login-identifier-section {
      display: flex;
      justify-content: center;

      .login-identifier-button {
        cursor: pointer;
        border: 1px solid #b2a9a9;
        font-variant: normal;
        text-transform: none;
        font-weight: 500;
        width: auto;
        span {
          display: contents;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .left-login-container {
      display: none;
    }
    .right-login-container {
      padding: 0.5rem 1rem 1.5rem 1rem;
    }
  }
}
