.oa-dialog-body {
    width: 35rem;
     display: flex;
      flex-direction: column;
       justify-content: center;
        align-items: center
}
@media only screen and (max-width: 600px) { 
    .oa-dialog-body{
        width: auto !important;
    }

}