.user-profile-account {
  margin: 0.5rem 0 0.1rem 1rem;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: normal;
  text-transform: uppercase;
}
.avatar-bg {
  border: 0.1rem solid;
}
.myprofile-item {
  a {
    display: flex;
    color: inherit;
    text-decoration: none;
  }
}
