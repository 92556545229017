.csc-list-header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    .header-back-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-back {
            display: flex;
            margin-right: 1rem;
            cursor: pointer;
        }
    }
    .header-text {
        font-size: 1.25rem;
        font-weight: 600;
        color: #000000;
  
    }
  }