.login-modal {
    border: none !important;
    border-radius: 4px !important;
}
.MuiButton-iconSizeMedium {
    margin-left: 0px !important;
}
.login-button {
    margin-right: 2% !important;
    margin-left: auto !important;
    border-radius: 5rem !important; 
    text-transform: none !important;
     height: 2rem !important; 
}
.light-dark-mode-icon {
    :hover {
        cursor: pointer;
    }
    margin-left: 1rem;
}
.avatar-bg {
    border: 0.10rem solid;
  }