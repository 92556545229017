.services-csc-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
}

.application-csc-card {
  padding: 1rem;
  width: 47.5%;
}

.application-card-list-container {
  padding: 1rem !important;
  border: none;
  box-shadow: 0 0 32px #c0c0c040;
  background-color: #ffffff !important;
  border-radius: 1.25rem;
  -webkit-border-radius: 20px;

  .MuiCardHeader-title {
    line-height: 1.6rem;
    font-size: 1rem;
    font-weight: 600 !important;
  }

  .MuiCardHeader-subheader {
    font-size: 0.875rem;
  }

  .card-content {
    padding: 0 !important;

    .service-price {
      margin-left: 0.5rem;
      line-height: 1.5rem;

    }

    .service-after-dis-price {
      display: inline-block;
      font-size: 1rem;
      font-weight: 700;
    }

    .service-before-dis-price {
      text-decoration: line-through;
      font-size: 14px;
      color: #878787;
    }

    .service-dis-percent {
      color: #388e3c;
      font-size: 0.8rem;
      letter-spacing: -.2px;
      font-weight: 500;
    }

    .service-location-style {
      padding: 0.20rem;
      display: flex;
      align-items: center;
    }
  }


  .MuiCardHeader-root {
    padding: 0 !important;
  }

  .card-media {
    max-height: 12rem !important;
    max-width: none !important;
  }

  .service-apply-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .service-apply-button {
      text-transform: none !important;

      &:hover {
        cursor: pointer;
        box-shadow: 0.25rem 0.25rem 0.5rem 0.5rem rgb(209, 213, 233) !important;
      }

    }
  }

  .application-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;

    .MuiCardActions-spacing {
      padding: 0rem !important;
    }

    .last-date {
      font-weight: 600 !important;
      margin-left: 0.5rem;
    }

    .csc-rating {
      background-color: #ebedf3;
      border-radius: 1.25rem;
      display: flex;
      align-items: center;
      padding: 0.375rem;
      justify-content: center;
    }

    .rating-text {
      font-size: 1rem;
    }
  }
}


@media only screen and (max-width: 600px) {
  .services-csc-list {
    display: flex;
    flex-wrap: wrap;
  }

  .header-order {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .application-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end !important;
  }


  .application-csc-card {
    padding: 1rem;
    width: 88%;
  }

}