.best-place-container {
    background: url('https://static.pbcdn.in/cdn/images/home-v1/left-bg.png') left center no-repeat,
        url('https://static.pbcdn.in/cdn/images/home-v1/right-bg.png') right center no-repeat;
    min-height: 510px;
    padding: 5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .best-place-heading {
        font-size: 1.5rem;
        min-width: 23rem;
        line-height: 3rem;

        span {
            font-weight: 500;
        }
    }

    .best-place-info-box {
        width: 12rem;
        vertical-align: top;
        background: #fff;
        text-align: left;
        display: inline-block;
        border-radius: 0.25rem;
        box-shadow: 0 0.35rem 1rem rgba(52, 105, 203, .16);
        position: relative;
        overflow: hidden;
        margin: 2.5rem 3rem 0 0;
     
        &.info {
            border-left: 2px solid rgb(30, 37, 77);
            .head {
                font-size: 1rem;
                font-weight: 600;
                padding: 0 16px;
                color: rgb(30, 37, 77);
            }
        }

        &.service-center {
            margin: 4rem 2.5rem 0 0;
            border-left: 2px solid #0bbdf2;
            .head {
                font-size: 1rem;
                font-weight: 600;
                padding: 0 16px;
                color: #0bbdf2;
            }
        }

        &.lowest-price {
            margin: 2rem 0 0 2rem;
            border-left: 2px solid #ffc400;
            .head {
                font-size: 1rem;
                font-weight: 600;
                padding: 0 16px;
                color: #ffc400;
            }
        }

        &.fast-service {
            border-left: 2px solid #47d990;
            margin: 3rem 0 1.25rem 7rem;
            .head {
                font-size: 1rem;
                font-weight: 600;
                padding: 0 16px;
                color: #47d990;
            }
        }
        &.live-status {
            border-left: 2px solid #f1280b;
            margin: 4rem 1rem 1rem 7rem;
            .head {
                font-size: 1rem;
                font-weight: 600;
                padding: 0 16px;
                color: #f1280b;
            }
        }

     

        .copy {
            font-size: 0.875rem;
            line-height: 21px;
            color: #253858;
            padding: 10px 11px 0 16px;
        }

        .top-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin: 1rem;
            display: inline-block;
        }

        &.info {
            margin: 2.5rem 3rem 0 0;
            border-left: 2px solid rgb(30, 37, 77);
        }
    }

}

@media only screen and (max-width: 600px) {
    .best-place-container {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: rgb(247 245 245 / 24%) #f7f5f1;
        display: flex;
        min-height: 15rem;
        border-radius: 1rem;
        margin: 1rem;
        padding: 0.25rem;
        align-items: center;
        background: #f5f6fc !important;
        .best-place-heading {
            font-size: 0.875rem;
            line-height: 1.25rem;
            min-width: 9rem;
        }

        .best-place-info-box {
            overflow: visible;
        }

    .best-place-right-block {
        display: flex;
        max-width: max-content;
    }



    .best-place-heading {
        text-align: center;
        margin: 0;
    }

    .info,
    .service-center,
    .lowest-price,
    .live-status,
    .fast-service {
        margin: 0.5rem !important;
    }
}

}