
.general-dialog-title {
    font-size: 1rem !important;
}
.dialog-title{
    display: flex !important;
    justify-content: center !important;
}
.oa-general-dialog {
    .MuiDialog-paper {
        width: 50%;
        overflow: hidden !important;
    }
}
@media only screen and (max-width: 600px) {

    .oa-general-dialog {
        .MuiDialog-paper {
            width: 100% !important;
            overflow: hidden !important;
        }
    }
}