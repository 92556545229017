.highlight {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold
}


@media screen and (max-width:700px) {
    .highlight {
        padding: 0.5rem;
        align-items: center;
        display: block;
        justify-content: center;
        font-weight: bold
    }

}
