.oa-toastr {
.MuiSnackbar-root {
    .MuiAlert-root{
        position: fixed;
        margin-top: 3rem;

    }
}
}
@media only screen and (max-width: 600px) { 
    .oa-toastr {
        .MuiSnackbar-root {
            .MuiAlert-root{
                position: fixed;
                margin: 5rem 0 0 0.5rem;
        
            }
        }
        }

}