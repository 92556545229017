.oa-service-choose-cards {
    padding: 3rem;
   display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .oa-choose-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .oa-choose-card {
            display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
        }
    }
}
@media only screen and (max-width:600px) {
    .oa-choose-card-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

    }

}