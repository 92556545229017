.oa-cs-container {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: repeating-linear-gradient(315deg, rgba(0, 255, 255, 0.1803921569) 92%, rgba(7, 58, 255, 0) 100%), repeating-radial-gradient(75% 75% at 238% 218%, rgba(0, 255, 255, 0.0705882353) 30%, rgba(7, 58, 255, 0.0784313725) 39%), radial-gradient(99% 99% at 109% 2%, #2b366e 0%, rgba(7, 58, 255, 0) 100%), radial-gradient(99% 99% at 21% 78%, #2b366e 0%, rgba(7, 58, 255, 0) 100%), radial-gradient(160% 154% at 711px -303px, #2b366e 0%, #2b366e 100%);
.heading {
    font-size: 1rem !important;
}

}


.clientImage {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.clientImage span {
    margin-left: 10px;
}

.clientImage img {
    width: 40px;
    border-radius: 50%;
}

.reviewSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.reviewItem {
    width: 300px;
    padding: 10px;
    margin: 1rem;
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: #2b366e;
    border: 1px solid #d7dae7;
    transition: all .2s linear;
}

.reviewItem:hover {
    border-color: #2b366e;
    transform: scale(1.01);
    background-color: #090921;
    box-shadow: 0 0px 5px 0px #cbc0c0;
}

.top {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

article p {
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 1rem;
    font-family: system-ui;
}


@media screen and (max-width:700px) {
    .oa-cs-container {
        height: auto;
    }
}

@media screen and (max-width:375px) {
    .reviewSection {
        padding: 0;
    }

    .reviewItem {
        width: 100%;
    }

    .clientImage {
        margin-bottom: 0.6rem;
    }

    .top {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
}