body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .css-qnq0pd-MuiPaper-root-MuiTableContainer-root {
  max-height: none !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media(max-width: 1280px) {
  html {
    font-size: 12.8px !important;
    /* 150% and 175% scaling 1920x1080
   regular laptops */
  }
}
@media(min-width: 1281px) and (max-width: 1536px){
  html {
    font-size: 14.6px !important;
    /* 125% scaling 1920x1080
    small monitors */
  }
}
@media(min-width: 1537px) {
  html {
    font-size: 16px !important;
    /* 100% scaling 1920x1080
    large and ultra wide monitors */
  }
}