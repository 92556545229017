
@media only screen and (min-width: 601px) {

    .general-application-card-list-container {
      position: relative;
      padding: 2rem;
      margin-left: 0.5rem;


// background: radial-gradient(circle, rgba(245,245,248,1) 0%, rgba(235,244,255,1) 3%, rgba(211,217,241,1) 100%);

	// background: linear-gradient(-45deg, #fff, #aad1e7, #c9c2d9, #0e33dfde);
	//  background-size: 400% 400%;
	// animation: gradient 5s ease infinite;
  


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
    }
    .application-qualification-header {
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
    }
    .general-application-card-list {
      display: flex;
      overflow-x: auto;
      scroll-behavior: smooth;
      padding-bottom: 0.5rem;
      scrollbar-width: none; 
      -ms-overflow-style: none; 
      &::-webkit-scrollbar {
        display: none; 
      }
    }
    .scroll-button {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      opacity: 0.8;
      border: none;
      color:  white;
      padding: 0.3rem;
      cursor: pointer;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .scroll-button-left {
      background-color: white;
      font-weight: bold; 
      border-radius: 100%;
      left:1rem;
    }
    .scroll-button-right {
      background-color: white;
        border-radius: 100%;
      right: 1rem;
      font-weight: bold; 
    }
  }
  @media only screen and (max-width: 600px) {
    .oa-service-category-section-header{
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 1rem !important;
      font-size: 0.75rem !important;
    }
    .home-page-main-heading-container {
      display: block !important;
    }
    .scroll-button {
      display: none;
    }
    .application-qualification-header {
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      margin: 1rem !important;
    }
   .home-headings {
        flex-basis: 50%;
     .home-page-heading-intro{
          font-size: 1rem !important;
          line-height: 2rem !important;
     }
        .home-page-heading {
          color: #44475b !important;
          font-weight: 600 !important;
          font-size:2rem;
          line-height: 2.5rem;
        }
        .home-page-heading-btn {
          font-size: 1rem !important;
          // width: 100% !important; /* Adjust button width */
          // padding: 1rem !important;
          margin-top: 1.5rem !important;
          text-transform:none;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
          cursor: pointer;
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 0.5rem 2px rgba(0, 0, 0, 0.2);
          }
        }  
      } 
       
  }
  .home-page-main-heading-container {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row; /* Stack elements vertically */
    text-align: center; /* Center-align text */
  }
  .home-headings {
    height: 100%;
    flex-basis: 100%;
    @-webkit-keyframes _ngcontent-serverApp-c99_ShineAnimation {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 100% 50%;
      }
    }
    
    @keyframes _ngcontent-serverApp-c99_ShineAnimation {
      0% {
        background-position: 0% 50%;
      }
      100% {
        background-position: 100% 50%;
      }
    }
    .home-page-heading-intro {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 3.5rem;
      background-image: linear-gradient(105deg, #75b469, #efce74, #fb833f, #fecc4f, #2b366e, #2b366e, #0149ff, #2b366e, #3e85fe, #883eec, #75b469);
      background-size: 900%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: _ngcontent-serverApp-c99_ShineAnimation 20s linear infinite both;
      -webkit-animation: _ngcontent-serverApp-c99_ShineAnimation 15s linear infinite both;
    }
    .home-page-heading-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .home-page-heading-btn {
      font-size: 1rem;
      margin-top: 3rem;
      text-transform:none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }
    }
    .home-page-subtitle {
      font-size: 1.5rem;
      color: #666;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      line-height: 2rem;
    }
  }
  .home-page-heading {
    color: #44475b !important;
    font-weight: 600 !important;
    font-size: 5rem;
    line-height: 6rem;
  }
  .oa-service-category-section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
    @keyframes gradientAnimation {
      0% {
        background-position: 0% 0%;
      }
      100% {
        background-position: 200% 0%;
      }
    }
    .horizontal-sep-div {
      display: flex;
      justify-content: center;
      padding: 3rem;
    .horizontal-separator {
      height: 4px;
      width: 50%;
      background-image: linear-gradient(90deg, #2b366e, #01a3ff, #2b366e, #2b366e, #2b366e);
      background-size: 200% auto;
      animation: gradientAnimation 5s ease infinite; /* Adjust animation duration and timing function as needed */
    }
  }  
  