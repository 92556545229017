.application-card {
  padding: 1rem;

}

.application-card-container {
  width: 15.5rem;
  flex: 0 0 auto;
  cursor: pointer;
  background-color: #ffffff !important;
  padding: 0.5rem !important;
  font-size: 1.2rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: .5s;
  }

  .MuiCardHeader-root {
    padding: 0 !important;
  }

  .MuiCardHeader-title {
    font-size: 1rem;
    font-weight: 600;
  }

  .card-media {
    max-height: 12rem !important;
    max-width: none !important;
  }


  .application-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 !important;

    .last-date {
      font-weight: 600 !important;
    }
  }
}


@media only screen and (max-width: 600px) {
  .application-card {
    padding: 1rem;
    margin-left: 0.8rem;
  }

  .application-card-container {
    width: auto;
  }

}