.form-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .person-form {
        padding: 1rem;
        width: 50%;
    }
}
@media only screen and (max-width: 600px) {
    .form-container{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: 120%;
        .person-form {
            padding: 1rem;
            width: 100%;
        }
    }
}