


@media only screen and (max-width:600px) { 
    .contact-us-container {
        flex-direction: column;
        justify-content: center;
        .contact-us-heading {
             width: 100% !important; 
            .contact-us-main-heading {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5 !important;
                font-weight: 700;
                 line-height: 2rem !important;
                margin-bottom: 0rem !important;
                padding: 2rem !important;
              }
              .contact-us-sub-heading {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0rem !important;
                padding: 1rem !important;
              }
        }
     }
}
.contact-us-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .contact-us-heading {
    width: 50%;
    .contact-us-main-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      font-weight: 700;
      line-height: 3rem;
      margin-bottom: 0.75rem;
    }
    .contact-us-sub-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }
  }
  .contact-us-heading-image {
  padding: 2rem;
  }
}
.assist-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 2.25rem;
}
.assist-sub-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 3.1rem;
}

