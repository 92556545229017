.oa-service-filter-heading {
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
     font-size: 1.6rem;
     font-weight: 600 !important;
     text-transform:uppercase;
     padding: 2rem;
}
.oa-filter-applications {
     display: flex;
     overflow-x: auto; /* Allow horizontal scrolling if content overflows */
     padding: 0rem 4rem 0rem 4rem;
     // :hover {
     //      cursor: pointer;
     //      transform: scale(1.01);
     //  }
   
     .oa-service-category-state,
     .oa-service-category-qualification,
     .oa-service-category-category,
     .oa-service-category-common-services {
       flex: 1 1 calc(50% - 1.4rem); /* Two columns on larger screens */
       margin: 0.3rem;
       height: 15rem;
       box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
       border-radius: 2rem;
       transition: 0.3s;
       display: flex;
       flex-direction: column;
       justify-content: space-around;
       align-items: flex-start;
       &:hover {
          cursor: pointer;
          transform: scale(1.01); 
        }


   
       .oa-service-category-text-header {
         background: hsla(0, 0%, 100%, 0.16);
         border-radius: 0 2rem 2rem 0;
         padding: 0.4rem 1rem 0.4rem 1.5rem;
         margin-top: 1.75rem;
         font-size: 0.75rem;
         font-weight: 600;
       }
   
       .oa-service-category-text-header-description {
         font-weight: 500;
         font-size: 1rem;
         color: #fff;
         text-align: left;
         margin: 0;
         letter-spacing: -0.02em;
         padding: 0.5rem;
         margin-left: 0.5rem;
       }
   
       .oa-service-category-text-header-forward {
         background: hsla(0, 0%, 100%, 0.32);
         padding: 0.2em;
         width: 4rem;
     //     height: 3rem;
         border-radius: 1.5rem;
         margin: 1rem 1em;
         display: flex;
         justify-content: center;
         align-items: center;
       }
     }
   
     .oa-service-category-state {
     //   background: conic-gradient(
     //       from 180deg at 50% 50%,
     //       #aa0047 0deg,
     //       #8a59f2 90deg,
     //       #aa0047 1turn
     //     ),
     //     #8a59f2;
     background: linear-gradient(-45deg, #ffffff, #1096e2, #4a0cd9, rgba(14, 51, 223, 0.8705882353));
     background-size: 400% 400%;
     animation: gradient 5s ease infinite;
     }
     .oa-service-category-qualification {
     //   background: conic-gradient(
     //       from 180deg at 50% 50%,
     //       #00aa3a 0deg,
     //       #0f2597 90deg,
     //       #00aa3a 1turn
     //     ),
     //     #8a59f2;
     background: linear-gradient(-45deg, #ffffff, #563e3e, #0f2597, rgba(5, 225, 112, 0.871));
     background-size: 400% 400%;
     animation: gradient 15s ease infinite;
     }
     .oa-service-category-category {
     //   background: conic-gradient(
     //       from 180deg at 50% 50%,
     //       #0007aa 0deg,
     //       #b9063c 90deg,
     //       #0007aa 1turn
     //     ),
     //     #8a59f2;
     background: linear-gradient(-45deg, #ffffff, #d30909, #4c6dc7, rgba(236, 4, 163, 0.871));
     background-size: 400% 400%;
     animation: gradient 7s ease infinite;
     }
     .oa-service-category-common-services {
          // background-image: repeating-linear-gradient(315deg, #00FFFF2E 92%, #073AFF00 100%),repeating-radial-gradient(75% 75% at 238% 218%, #00FFFF12 30%, #073AFF14 39%),radial-gradient(99% 99% at 109% 2%, #00C9FFFF 0%, #073AFF00 100%),radial-gradient(99% 99% at 21% 78%, #7B00FFFF 0%, #073AFF00 100%),radial-gradient(160% 154% at 711px -303px, #2000FFFF 0%, #073AFFFF 100%);
       
          background: linear-gradient(-45deg, #ffffff, #0d646d, #8327b8, rgba(148, 116, 101, 0.871));
     background-size: 400% 400%;
     animation: gradient 13s ease infinite;
     }

   }


      
   @media only screen and (max-width: 600px) {
    
@keyframes gradientfilters {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
     .oa-filter-applications {
          display: flex;
          flex-direction: column;
          .oa-service-category-state{
            animation: gradientfilters 5s ease infinite;
          }
          .oa-service-category-qualification{
            animation: gradientfilters 15s ease infinite;

          }
          .oa-service-category-category{
            animation: gradientfilters 7s ease infinite;

          }
          .oa-service-category-services {
            animation: gradientfilters 13s ease infinite;
          }
     
     .oa-service-category-state,
     .oa-service-category-qualification,
     .oa-service-category-category,
     .oa-service-category-services {
       flex: 1 1 calc(100% - 1.4rem);
       
     }
    }
   }
   
   